<template>
	<div id="content-frame">
		<div id="main-panel">
			<auth-container :page="page">
				<b-container fluid class="p-0">
					<b-row no-gutters>
						<b-col md="3" lg="2" order="2" order-md="1" class="fixed-height bg-light">
							<div class="px-3 pt-4">
								<h6 class="pt-3">Conferences</h6>
								<div v-if="conferences.length < 1" class="border p-2 bg-white">
									<small>None available at this time.</small>
								</div>
								<b-nav v-else vertical class="dash-items-nav nav-lg">
									<b-nav-item v-for="(c, i) in conferences" :key="i" :to="`/manage/papers/${c.id}`">
										<font-awesome-icon :icon="['fas', 'globe']" fixed-width class="mr-3" /> {{c.name}}
										<b-nav vertical class="nested py-0" v-if="isAuthorized(roleNames.admin)">
											<b-nav-item :to="`/manage/registrations/${c.id}`">Registrations</b-nav-item>
										</b-nav>
									</b-nav-item>
								</b-nav>

								<div v-if="isAuthorized(roleNames.admin)" class="mt-5">
									<h6>Administration</h6>
									<b-nav vertical class="dash-items-nav nav-lg">
										<b-nav-item to="/manage/users">
											<font-awesome-icon :icon="['fas', 'user-friends']" fixed-width class="mr-3" /> Users
										</b-nav-item>
										<b-nav-item to="/manage/conferences">
											<font-awesome-icon :icon="['fas', 'globe-americas']" fixed-width class="mr-3" /> Conferences
										</b-nav-item>
										<b-nav-item to="/manage/topics">
											<font-awesome-icon :icon="['fas', 'list-ol']" fixed-width class="mr-3" /> Topics
										</b-nav-item>
										<b-nav-item to="/manage/invitations">
											<font-awesome-icon :icon="['fas', 'envelope-open-text']" fixed-width class="mr-3" /> Invitations
										</b-nav-item>
										<b-nav-item to="/manage/certificates">
											<font-awesome-icon :icon="['fas', 'certificate']" fixed-width class="mr-3" /> Certificates
										</b-nav-item>
										<b-nav-item to="/manage/regparsers">
											<font-awesome-icon :icon="['fas', 'table']" fixed-width class="mr-3" /> Registration Parsers
										</b-nav-item>
									</b-nav>
								</div>

								<div class="mt-5">
									<h6>Account</h6>
									<b-nav vertical class="dash-items-nav nav-lg">
										<b-nav-item to="/manage/change-password">
											<font-awesome-icon :icon="['fas', 'key']" fixed-width class="mr-3" /> Change Password
										</b-nav-item>
									</b-nav>
								</div>
							</div>
						</b-col>
						<b-col md="9" lg="10" order="1" order-md="2" class="fixed-height shadow-sm no-shadow-sm" style="z-index:500">
							<b-breadcrumb :items="breadCrumbs" class="bg-white px-md-4 mb-0"></b-breadcrumb>
							<div class="container-fluid px-md-4 pt-2 pb-4">
								<div v-if="$route.name === 'ManageDashboard'">
									<h1>SWAT Conference Abstract Management</h1>
									<p class="lead">
										This site manages abstract submissions for Soil and Water Assessment Tool (SWAT) Conferences.
										As a member of the scientific committee, you may approve, reject, and suggest improvements 
										for abstracts submitted to topics and conferences to which you are assigned.
									</p>
								</div>
								<router-view></router-view>
							</div>
						</b-col>
					</b-row>
				</b-container>
			</auth-container>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ManageDashboard',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				conferences: []
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`conferences/mylist`, this.getTokenHeader());
					this.log(response.data);
					this.conferences = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		},
		computed: {
			breadCrumbs() {
				var crumbs = [];
				for (var i = 0; i < this.$route.matched.length; i++) {
					var item = this.$route.matched[i];
					var name = item.meta.title ? item.meta.title : item.name;

					crumbs.push({
						text: name,
						to: { name: item.name }
					});
				}
				return crumbs;
			}
		}
	}
</script>